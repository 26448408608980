<template>
  <v-row v-if="room" no-gutters class="fill-width">
    <v-col cols="12" class="col-sm-2">
      <v-img
        :src="$getImage(room.galleries || '')"
        :lazy-src="$getImage(room.galleries || '')"
        aspect-ratio="1"
        class="grey lighten-2"
        width="100%"
      >
        <template #placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
      </v-img>
    </v-col>
    <v-col cols="12" class="col-sm-10 pl-2 pl-sm-4 pr-2 pr-sm-2 pt-0 pt-sm-2 pb-4 pb-sm-2">
      <div class="d-flex align-center fill-height fill-width pa-4">
        <v-row no-gutters>
          <v-col cols="12" class="d-flex flex-column col-sm-9 pr-0 pr-sm-8">
            <div>
              <div @click.stop>
                <div class="d-flex align-center caption">
                  <v-icon x-small class="mr-1">
                    mdi-office-building-marker-outline
                  </v-icon>
                  {{ room.hotel_name }}
                </div>
              </div>
              <div class="font-weight-bold title primary--text">
                {{ room.name }}
              </div>
              <div class="d-flex align-center">
                <div class="d-flex align-center caption primary--text mr-2 font-weight-bold">
                  <v-icon small class="mr-1" color="primary">
                    mdi-door-open
                  </v-icon>
                  {{ room.room_total }} Rooms
                  <v-icon x-small class="ml-1" color="black">
                    mdi-arrow-right-bold
                  </v-icon>
                </div>
                <div class="d-flex align-center caption orange--text">
                  <v-icon small class="mr-2" color="orange">
                    mdi-bed-king-outline
                  </v-icon>
                  {{ room.bed_type }}
                </div>
                <v-divider vertical class="mx-2" />
                <div class="d-flex align-center caption orange--text">
                  <v-icon small class="mr-2" color="orange">
                    mdi-account-multiple
                  </v-icon>
                  {{ room.capacity }} Guest
                </div>
              </div>
            </div>
            <RoomFacilities :value="room" />
            <div class="caption grey--text text--darken-3">
              Description : {{ room.description || '-' }}
            </div>
          </v-col>
          <v-col cols="12" class="d-flex flex-column justify-space-between col-sm-3 text-right py-4 py-sm-0">
            <div>
              <div class="d-flex flex-row flex-sm-column align-start align-sm-end justify-end pb-2">
                <div :class="[parseInt(room.status) ? 'blue--text text--darken-2' : 'orange--text']" class="d-flex align-center caption">
                  <v-icon :color="parseInt(room.status) ? 'blue darken-2' : 'orange'" class="mr-1" x-small>
                    {{ parseInt(room.status) ? 'mdi-shield-check-outline' : 'mdi-alert' }}
                  </v-icon>
                  {{ parseInt(room.status) ? 'Published' : 'Not Published' }}
                </div>
                <!-- <div v-if="parseFloat(room.price_discount)" class="text-decoration-line-through red--text subtitle-1">
                  {{ $price(room.price) }}
                </div> -->
                <div class="title primary--text font-weight-bold ml-2">
                  {{ $price(parseFloat(room.price)) }}
                  <div class="caption ml-2">
                    /room /night
                  </div>
                </div>
              </div>
              <div :disabled="!$role(['manager'], $store.state.user.role)" @click.stop>
                <v-btn
                  color="secondary"
                  icon
                  small
                  class="d-inline-flex text-capitalize caption font-weight-bold mr-4"
                  @click.prevent="$emit('open-gallery', { id: room.id, type: 'room', title: room.name, galleries: room.galleries })"
                >
                  <v-icon>
                    mdi-image-multiple-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  small
                  class="px-4 d-inline-flex text-capitalize caption font-weight-bold"
                  @click.prevent="$emit('open-detail', Object.assign({}, room))"
                >
                  Update
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="pt-4 pb-0">
            <v-divider class="mt-4 mb-4 mt-sm-0" />
            <div
              :class="[room.showTable ? 'secondary--text' : '']"
              class="d-flex flex-column-reverse flex-sm-row align-center justify-center justify-sm-space-between body-2 font-weight-bold"
            >
              <div class="d-flex align-center c-pointer pt-4 pt-sm-0" style="user-select: none;" @click.prevent="$store.commit('room/SET_ROOM_TABLE', { id: parseInt(room.id), show: !room.showTable })">
                <v-icon :color="room.showTable ? 'secondary' : ''" class="mr-2" small>
                  mdi-timetable
                </v-icon>
                Booking Table
                <v-icon :color="room.showTable ? 'secondary' : ''" class="ml-1" small>
                  {{ room.showTable ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </div>
              <div @click.stop>
                <v-btn class="text-capitalize font-weight-bold px-4" small dark outlined color="primary" @click.prevent="$emit('open-rooms', room)">
                  Default List Rooms
                </v-btn>
                <!-- <v-btn color="secondary" dark small text class="text-capitalize">
                  <v-icon class="mr-1" small>
                    mdi-cash-100
                  </v-icon>
                  Surcharge Price
                </v-btn> -->
              </div>
            </div>
            <Calendar
              v-if="room.showTable"
              :ref-calendar="'calendar-room-' + room.id"
              :room="room"
              :booking="{}"
            />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    room: {
      type: Object,
      default: () => {}
    },
    filterSelected: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    openDateMenu: false,
    openDateEvent: {},
    openDateElement: null,
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days'
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party']
  }),
  mounted () {
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.openDateEvent = event
        this.openDateElement = nativeEvent.target
        console.log(event, nativeEvent.target)
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.openDateMenu = true
        }))
      }
      if (this.openDateMenu) {
        this.openDateMenu = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        // const allDay = this.rnd(0, 3) === 0
        // const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        // const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        // const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        // const second = new Date(first.getTime() + secondTimestamp)

        // events.push({
        //   name: this.names[this.rnd(0, this.names.length - 1)],
        //   start: first,
        //   end: second,
        //   color: this.colors[this.rnd(0, this.colors.length - 1)],
        //   timed: !allDay
        // })
      }
      events.push({
        name: '50',
        start: new Date('2022-07-10T13:00:00'),
        end: new Date('2022-07-10T11:00:00'),
        color: 'grey lighten-2',
        timed: true
      })

      events.push({
        name: '30',
        start: new Date('2022-07-11T07:20:00'),
        end: new Date('2022-07-11T09:20:00'),
        color: 'grey lighten-2',
        timed: true
      })

      events.push({
        name: '75',
        start: new Date('2022-07-12T09:20:00'),
        end: new Date('2022-07-12T12:20:00'),
        color: 'grey lighten-2',
        timed: true
      })

      this.events = events
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  }
}
</script>

<style lang="scss">
</style>
